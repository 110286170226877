import { EmailResponse } from '@Api-generated';

export enum EmailType {
  Primary = 'primary',
  Google = 'google',
  Facebook = 'facebook',
}

export default class EmailModel implements EmailResponse {
  public id: string;
  public email: string;
  public emailType: EmailType;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(id: string, email: string, emailType: EmailType, createdAt?: string, updatedAt?: string) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.email = email;
    this.emailType = emailType;
  }

  public static hydrateFromApi(emailResponse: EmailResponse) {
    const emailType = emailResponse.emailType as EmailType;

    return new EmailModel(
      emailResponse.id,
      emailResponse.email,
      emailType,
      emailResponse.createdAt,
      emailResponse.updatedAt
    );
  }
}
