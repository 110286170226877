import { LanguageResponse } from '@Api-generated';

export default class LanguageModel implements LanguageResponse {
  public id: string;
  public name: string;
  public languageNative: string;
  public iso: string;
  public createdAt?: string;
  public updatedAt?: string;
  public isLtr?: boolean;
  public top200Words?: string;
  public top1000Words?: string;
  public top5000Words?: string;

  constructor(
    id: string,
    name: string,
    languageNative: string,
    iso: string,
    createdAt?: string,
    updatedAt?: string,
    isLtr?: boolean,
    top200Words?: string,
    top1000Words?: string,
    top5000Words?: string
  ) {
    this.id = id;
    this.name = name;
    this.languageNative = languageNative;
    this.iso = iso;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.isLtr = isLtr;
    this.top200Words = top200Words;
    this.top1000Words = top1000Words;
    this.top5000Words = top5000Words;
  }

  public static hydrateFromApi(languageResponse: LanguageResponse) {
    return new LanguageModel(
      languageResponse.id,
      languageResponse.name,
      languageResponse.languageNative,
      languageResponse.iso,
      languageResponse.createdAt,
      languageResponse.updatedAt,
      languageResponse.isLtr,
      languageResponse.top200Words,
      languageResponse.top1000Words,
      languageResponse.top5000Words
    );
  }
}
