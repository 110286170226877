import styled from 'styled-components';

export const Content = styled.div<{ maxWidth?: number }>`
  padding: ${(props) => props.theme.space.default_16};
  background-color: ${(props) => props.theme.palette.popOver.background.default};
  border: 1px solid ${(props) => props.theme.palette.popOver.border};
  border-radius: ${(props) => props.theme.borderRadius.box};
  z-index: 200;
  max-width: ${(props) => (props.maxWidth === undefined ? 'auto' : `${props.maxWidth}px`)};
  box-shadow: ${(props) => props.theme.palette.popOver.shadow};
`;
