import styled from 'styled-components';

interface RootProps {
  readonly width: string;
  readonly height: string;
  readonly round: boolean;
}

export const Root = styled.div<RootProps>`
  display: inline-block;
  position: relative;
  overflow: hidden;
  min-height: ${(props) => props.height};
  max-height: ${(props) => props.height};
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  background-color: ${(props) => props.theme.palette.skeleton};
  border-radius: ${(props) => (props.round ? '50%' : '4px')};
  color: transparent !important;
  border: transparent !important;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, ${(props) => props.theme.palette.skeleton}, transparent);
    animation: shimmer 1.6s infinite;
    content: '';
    z-index: 9999;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  & > * {
    visibility: hidden;
  }
`;
