import { LanguageResponse } from '@Api-generated';

import { SingleOption } from '@Components/atoms/Form/InputSelect/InputSelect';

import languages from '@Assets/lang/typingtestLanguages.json';

export const DEFAULT_LANGUAGE_ISO = 'en';
export const DEFAULT_LANGUAGE_NAME = 'english';

export const englishSingleOptionFallback: SingleOption = {
  value: DEFAULT_LANGUAGE_ISO,
  label: 'English (english)',
};

// this function returns a language model array build by a static json from the frontend
export const getStaticLanguages = (): LanguageResponse[] => {
  return languages.data;
};

export const getInitialLanguage = (currentLocale?: string): SingleOption => {
  const locale = currentLocale ?? englishSingleOptionFallback.value;

  const initialLanguage = getStaticLanguages().find((language) => language.iso === locale);

  if (!initialLanguage) {
    return englishSingleOptionFallback;
  }

  return {
    value: initialLanguage.iso,
    label: `${initialLanguage.languageNative} (${initialLanguage.name})`,
  };
};

export const getLanguageNameByIso = (iso?: string, capitalize = true): string | undefined => {
  if (!iso) {
    return undefined;
  }

  const langauge = getStaticLanguages().find((language) => language.iso === iso);

  if (!langauge) {
    return undefined;
  }

  if (capitalize) {
    return capitalizeFirstLetter(langauge.name);
  }

  return langauge.name;
};

export const getLanguageOptionByIso = (iso: string | undefined): SingleOption | undefined => {
  if (!iso) {
    return undefined;
  }

  const lang = getStaticLanguages().find((singleLanguage) => singleLanguage.iso === iso);
  if (!lang) {
    return undefined;
  }
  return {
    value: lang.iso,
    label: lang.languageNative + ' (' + lang.name + ')',
  };
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
