import { i18n, Resource } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';

import i18nConfig from '@Root/i18nConfig';

const initClientTranslations = async (
  locale: string,
  namespaces: string[],
  i18nInstance: i18n,
  resources: Resource
) => {
  i18nInstance.use(initReactI18next);

  await i18nInstance.init({
    lng: locale,
    resources,
    fallbackLng: i18nConfig.defaultLocale,
    supportedLngs: i18nConfig.locales,
    defaultNS: namespaces[0],
    fallbackNS: namespaces[0],
    ns: namespaces,
    preload: resources ? [] : i18nConfig.locales,
  });
};

export default initClientTranslations;
