import { addSeparator } from '@Components/helper/gameModeService';
import { Routes } from '@Components/Routes';

import { CustomTypingTestDuration } from '@Helpers/types/custom-typing-test';

export enum SocialAuthType {
  Google = 'google',
  Facebook = 'facebook',
  Discord = 'discord',
}

export function getDynamicSubRoute(route: Routes, ...parameters: string[]) {
  return `${route}/${parameters.map((parameter) => slugify(parameter)).join('/')}`;
}

export function buildRouteWithLocal(locale: string, route: Routes) {
  return `/${locale}${route}`;
}

export function getPrintableUrl(route: string) {
  return process.env.NEXT_PUBLIC_BASE_URL + route;
}

export function getPrintableCompetitionLink(urlHash: string): string {
  return getPrintableUrl(getDynamicSubRoute(Routes.Competition, urlHash));
}

export function getPrintableCustomTypingTestLink(
  words: string,
  duration: CustomTypingTestDuration,
  isRandomized: boolean,
  languageIso?: string
): string {
  const url = new URL(getPrintableUrl(Routes.CustomTypingTest));

  url.searchParams.append('rand', isRandomized.toString());
  url.searchParams.append('dur', duration.toString());

  if (languageIso) {
    url.searchParams.append('lang', languageIso);

    return url.toString();
  }

  url.searchParams.append('words', addSeparator(words));

  return url.toString();
}

function getRootURLSecure(): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return process.env.NEXT_PUBLIC_API_ROOT_URL?.replace(/^http:\/\//i, 'https://');
}

export function getBackendImageUpload(purpose: string, name: string): string {
  return new URL(`${getRootURLSecure()}/image/${purpose}/${name}`).toString();
}

export function getRegisterSocialAuthBackendUrl(socialAuthType: SocialAuthType, userId?: string): string {
  const url = new URL(`${getRootURLSecure()}/auth/${socialAuthType}/register`);

  if (userId) {
    url.searchParams.append('userId', userId);
  }

  return url.toString();
}

export function getCreateStripeSubscriptionBackendUrl(priceId: string, userId?: string): string {
  const url = new URL(getRootURLSecure() + '/account/payment/stripe/subscription/create');

  url.searchParams.append('price', priceId);

  if (userId) {
    url.searchParams.append('userId', userId);
  }

  return url.toString();
}

export function getManageStripeSubscriptionBackendUrl(userId?: string): string {
  const url = new URL(getRootURLSecure() + '/account/payment/stripe/subscription/manage');

  if (userId) {
    url.searchParams.append('userId', userId);
  }

  return url.toString();
}

export const validateQueryParameter = (
  queryParam: string[] | string | undefined,
  arraySeparator = ', '
): string | undefined => {
  if (Array.isArray(queryParam)) {
    return queryParam.join(arraySeparator);
  }

  if (queryParam === '') {
    return undefined;
  }

  return queryParam;
};

export const slugify = (value: string) => {
  return String(value)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/_/g, '-') // replace underscore with hyphens
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
};

export const isExternalUrl = (url: string): boolean => {
  const regExp = new RegExp('^(?:[a-z+]+:)?//', 'i');

  return regExp.test(url);
};
