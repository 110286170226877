import { ThemeColorsResponse } from '@Api-generated';

export default class ThemeColorsModel implements ThemeColorsResponse {
  public primary: string;
  public accent: string;
  public primaryText: string;
  public link: string;
  public backgroundGradientStart: string;
  public backgroundGradientEnd: string;
  public mainGradientStart: string;
  public mainGradientEnd: string;
  public backgroundAccent: string;
  public backgroundHeader: string;
  public backgroundBox: string;
  public backgroundInputButton: string;
  public backgroundFooter: string;
  public footerText: string;
  public border: string;
  public shadow: string;
  public wordBoxActive: string;
  public wordBoxTyped: string;
  public wordBoxCursor: string;
  public success: string;
  public info: string;
  public warning: string;
  public error: string;
  public backgroundChart1GradientStart: string | null;
  public backgroundChart1GradientEnd: string | null;
  public backgroundChart2GradientStart: string | null;
  public backgroundChart2GradientEnd: string | null;

  constructor(
    primary: string,
    accent: string,
    primaryText: string,
    link: string,
    backgroundGradientStart: string,
    backgroundGradientEnd: string,
    mainGradientStart: string,
    mainGradientEnd: string,
    backgroundAccent: string,
    backgroundHeader: string,
    backgroundBox: string,
    backgroundInputButton: string,
    backgroundFooter: string,
    footerText: string,
    border: string,
    shadow: string,
    wordBoxActive: string,
    wordBoxTyped: string,
    wordBoxCursor: string,
    success: string,
    info: string,
    warning: string,
    error: string,
    backgroundChart1GradientStart: string | null,
    backgroundChart1GradientEnd: string | null,
    backgroundChart2GradientStart: string | null,
    backgroundChart2GradientEnd: string | null
  ) {
    this.primary = primary;
    this.accent = accent;
    this.primaryText = primaryText;
    this.link = link;
    this.backgroundGradientStart = backgroundGradientStart;
    this.backgroundGradientEnd = backgroundGradientEnd;
    this.mainGradientStart = mainGradientStart;
    this.mainGradientEnd = mainGradientEnd;
    this.backgroundAccent = backgroundAccent;
    this.backgroundHeader = backgroundHeader;
    this.backgroundBox = backgroundBox;
    this.backgroundInputButton = backgroundInputButton;
    this.backgroundFooter = backgroundFooter;
    this.footerText = footerText;
    this.border = border;
    this.shadow = shadow;
    this.wordBoxActive = wordBoxActive;
    this.wordBoxTyped = wordBoxTyped;
    this.wordBoxCursor = wordBoxCursor;
    this.success = success;
    this.info = info;
    this.warning = warning;
    this.error = error;
    this.backgroundChart1GradientStart = backgroundChart1GradientStart;
    this.backgroundChart1GradientEnd = backgroundChart1GradientEnd;
    this.backgroundChart2GradientStart = backgroundChart2GradientStart;
    this.backgroundChart2GradientEnd = backgroundChart2GradientEnd;
  }

  public static hydrateFromApi(themeColorsResponse: ThemeColorsResponse): ThemeColorsModel {
    return new ThemeColorsModel(
      themeColorsResponse.primary,
      themeColorsResponse.accent,
      themeColorsResponse.primaryText,
      themeColorsResponse.link,
      themeColorsResponse.backgroundGradientStart,
      themeColorsResponse.backgroundGradientEnd,
      themeColorsResponse.mainGradientStart,
      themeColorsResponse.mainGradientEnd,
      themeColorsResponse.backgroundAccent,
      themeColorsResponse.backgroundHeader,
      themeColorsResponse.backgroundBox,
      themeColorsResponse.backgroundInputButton,
      themeColorsResponse.backgroundFooter,
      themeColorsResponse.footerText,
      themeColorsResponse.border,
      themeColorsResponse.shadow,
      themeColorsResponse.wordBoxActive,
      themeColorsResponse.wordBoxTyped,
      themeColorsResponse.wordBoxCursor,
      themeColorsResponse.success,
      themeColorsResponse.info,
      themeColorsResponse.warning,
      themeColorsResponse.error,
      themeColorsResponse.backgroundChart1GradientStart,
      themeColorsResponse.backgroundChart1GradientEnd,
      themeColorsResponse.backgroundChart2GradientStart,
      themeColorsResponse.backgroundChart2GradientEnd
    );
  }

  public static hydrateFromImportedJson(themeColorsJson: string): ThemeColorsModel {
    const themeColors = JSON.parse(themeColorsJson);

    const themeColorsModel = new ThemeColorsModel(
      themeColors.primary,
      themeColors.accent,
      themeColors.primaryText,
      themeColors.link,
      themeColors.backgroundGradientStart,
      themeColors.backgroundGradientEnd,
      themeColors.mainGradientStart,
      themeColors.mainGradientEnd,
      themeColors.backgroundAccent,
      themeColors.backgroundHeader,
      themeColors.backgroundBox,
      themeColors.backgroundInputButton,
      themeColors.backgroundFooter,
      themeColors.footerText,
      themeColors.border,
      themeColors.shadow,
      themeColors.wordBoxActive,
      themeColors.wordBoxTyped,
      themeColors.wordBoxCursor,
      themeColors.success,
      themeColors.info,
      themeColors.warning,
      themeColors.error,
      themeColors.backgroundChart1GradientStart,
      themeColors.backgroundChart1GradientEnd,
      themeColors.backgroundChart2GradientStart,
      themeColors.backgroundChart2GradientEnd
    );

    const errors = Object.keys(themeColorsModel)
      .filter((key) => themeColorsModel[key as keyof ThemeColorsModel] === undefined)
      .map((key) => `Color [${key}] is missing`);

    if (errors.length > 0) {
      throw new Error(errors.join('\n'));
    }

    return themeColorsModel;
  }
}
