import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Root } from './Skeleton.styles';

interface Props extends PropsWithClassName {
  width?: string;
  height?: string;
  children?: React.ReactNode;
  round?: boolean;
  as?: string;
}

const Skeleton = (props: Props): React.ReactElement => {
  const { dataTestId, children, width, height, round } = props;

  return (
    <Root
      width={width ?? 'auto'}
      height={height ?? 'auto'}
      className={props.className}
      round={round ?? false}
      data-testid={dataTestId ? dataTestId : 'Skeleton-root'}
    >
      {children}
    </Root>
  );
};

export { Skeleton };
