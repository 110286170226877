'use client';

import React from 'react';

import { PropsWithClassName } from '@Components/helper';

import { TextAlign, TextColor, TextSize, TextTransform, TextWeight } from '@Helpers/types/text';

import { Root } from './Text.styles';

interface Props extends PropsWithClassName {
  children: string | React.ReactNode;
  size: TextSize;
  bold?: boolean;
  color?: TextColor;
  onClick?: () => void;
  textAlign?: TextAlign;
  textTransform?: TextTransform;
  as?: keyof JSX.IntrinsicElements;
  isTruncated?: boolean;
}

const Text = (props: Props): React.ReactElement => {
  const {
    children,
    bold,
    size,
    dataTestId,
    onClick,
    color,
    textAlign = TextAlign.Initial,
    textTransform = TextTransform.Unset,
    as,
    isTruncated = false,
  } = props;

  return (
    <Root
      $weight={bold ? TextWeight.SemiBold : TextWeight.Light}
      $size={size}
      as={as}
      $color={color ?? TextColor.Primary}
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'Text-root'}
      onClick={onClick}
      $textAlign={textAlign}
      $textTransform={textTransform}
      $isTruncated={isTruncated}
    >
      {children}
    </Root>
  );
};

export { Text };
