import { UserDiscordResponse } from '@Api-generated';

export default class UserDiscordModel implements UserDiscordResponse {
  public id: string;
  public username: string;
  public showUsernameInProfile: boolean;
  public discordId?: string;

  constructor(id: string, username: string, showUsernameInProfile: boolean, discordId?: string) {
    this.id = id;
    this.username = username;
    this.showUsernameInProfile = showUsernameInProfile;
    this.discordId = discordId;
  }

  public static hydrateFromApi(userDiscordResponse: UserDiscordResponse): UserDiscordModel {
    return new UserDiscordModel(
      userDiscordResponse.id,
      userDiscordResponse.username,
      userDiscordResponse.showUsernameInProfile,
      userDiscordResponse.discordId
    );
  }
}
