import LanguageModel from '@Api/models/LanguageModel';
import TestResultModel from '@Api/models/TestResultModel';
import UserModel from '@Api/models/UserModel';

import { TypingTestResultResponse } from '@Api-generated';

import { Word } from '@Components/organisms/TypingBox/useTyping.types';

export enum TypingMode {
  Normal = 'normal',
  Advanced = 'advanced',
}

export default class TypingTestResultModel implements TypingTestResultResponse {
  public id: string;
  public createdAt: string;
  public updatedAt: string;
  public typingMode: TypingMode;
  public isLocked: boolean;
  public testResult: TestResultModel;
  public language: LanguageModel;
  public user: UserModel;
  public words?: Word[];

  constructor(
    id: string,
    createdAt: string,
    updatedAt: string,
    typingMode: TypingMode,
    isLocked: boolean,
    testResult: TestResultModel,
    language: LanguageModel,
    user: UserModel,
    words?: Word[]
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.typingMode = typingMode;
    this.isLocked = isLocked;
    this.testResult = testResult;
    this.language = language;
    this.user = user;
    this.words = words;
  }

  public static hydrateFromApi(typingTestResultResponse: TypingTestResultResponse) {
    const languageModel = LanguageModel.hydrateFromApi(typingTestResultResponse.language);
    const userModel = UserModel.hydrateFromApi(typingTestResultResponse.user);
    const testResultModel = TestResultModel.hydrateFromApi(typingTestResultResponse.testResult);

    const typingMode = (typingTestResultResponse.typingMode as TypingMode) ?? TypingMode.Normal;

    return new TypingTestResultModel(
      typingTestResultResponse.id,
      typingTestResultResponse.createdAt,
      typingTestResultResponse.updatedAt,
      typingMode,
      typingTestResultResponse.isLocked,
      testResultModel,
      languageModel,
      userModel,
      typingTestResultResponse.words as Word[]
    );
  }
}
