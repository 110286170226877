import { formatDistanceStrict, isValid } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import * as locales from 'date-fns/locale';

export enum DateTimeFormats {
  LocalizedDateTime = 'Pp', // formats Date based on the users locale e.g. 09/18/2020, 1:11 PM
  LocalizedDate = 'P',
  LocalizedDateLong = 'PPPP',
  LocalizedDateFullMonth = 'dd. LLLL yyyy',
  DayOfWeek = 'eee',
}

export const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateInTimezone = (date: string) => {
  const newTimezone = getBrowserTimezone();
  const dateWithTimeZone = utcToZonedTime(date, newTimezone);

  if (!isValid(dateWithTimeZone)) {
    throw new Error(
      `Unexpected Error while formatting Date [${date}] to date with timezone [${newTimezone}]. One of these values might be invalid`
    );
  }

  return dateWithTimeZone;
};

export const formatDate = (
  date: string,
  locale: string,
  dateFormat: DateTimeFormats = DateTimeFormats.LocalizedDateTime
): string => {
  const dateInTimezone = getDateInTimezone(date);

  return format(dateInTimezone, dateFormat, {
    locale: locales[locale as keyof typeof locales],
  });
};

export const getDistance = (
  distanceDate: string,
  locale: string,
  baseDate?: Date,
  timezone?: string,
  addSuffix?: boolean
): string => {
  const newTimezone = timezone ?? getBrowserTimezone();
  const newBaseDate = baseDate ?? new Date();
  const dateWithTimeZone = utcToZonedTime(distanceDate, newTimezone);

  if (!isValid(dateWithTimeZone)) {
    throw new Error(
      `Unexpected Error while formatting Date [${distanceDate}] to date with timezone [${newTimezone}]. One of these values might be invalid`
    );
  }

  return formatDistanceStrict(dateWithTimeZone, newBaseDate, {
    locale: locales[locale as keyof typeof locales],
    addSuffix: addSuffix ?? false,
  });
};
