export const range = (start: number, count: number) => {
  return Array.from(Array.from(Array(count).keys()), (x) => start + x);
};

export const getStringWithLength = (length: number) => {
  return 'a'.repeat(length);
};

export const isStringOnlySpaces = (value: string) => {
  return /^\s+$/.test(value);
};

export const download = (data: Blob, filename: string) => {
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.download = filename;
  a.href = url;
  a.target = '_self';

  a.click();

  setTimeout(function () {
    // for Firefox it is necessary to delay revoking the ObjectURL
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
};
