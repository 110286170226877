import ThemeColorsModel from '@Api/models/ThemeColorsModel';
import UserModel from '@Api/models/UserModel';

import { ThemeResponse } from '@Api-generated';

import { ThemeIconSet, ThemeType } from '@Assets/styles/theme/theme';

export default class ThemeModel implements ThemeResponse {
  public id: string;
  public createdAt?: string;
  public updatedAt?: string;
  public name: string;
  public themeType?: ThemeType;
  public iconSet?: ThemeIconSet;
  public colors?: ThemeColorsModel;
  public isPublished?: boolean;
  public isPrimary?: boolean;
  public createdBy?: UserModel | null;

  constructor(
    id: string,
    name: string,
    createdAt?: string,
    updatedAt?: string,
    themeType?: ThemeType,
    iconSet?: ThemeIconSet,
    colors?: ThemeColorsModel,
    isPublished?: boolean,
    isPrimary?: boolean,
    createdBy?: UserModel | null
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.name = name;
    this.themeType = themeType;
    this.iconSet = iconSet;
    this.colors = colors;
    this.isPublished = isPublished;
    this.isPrimary = isPrimary;
    this.createdBy = createdBy;
  }

  public static hydrateFromApi(themeResponse: ThemeResponse): ThemeModel {
    const createdByModel = themeResponse.createdBy
      ? UserModel.hydrateFromApi(themeResponse.createdBy)
      : themeResponse.createdBy;

    const themeColors = themeResponse.colors
      ? ThemeColorsModel.hydrateFromApi(themeResponse.colors)
      : themeResponse.colors;

    const themeType = themeResponse.themeType as ThemeType;
    const iconSet = themeResponse.iconSet as ThemeIconSet;

    return new ThemeModel(
      themeResponse.id,
      themeResponse.name,
      themeResponse.createdAt,
      themeResponse.updatedAt,
      themeType,
      iconSet,
      themeColors,
      themeResponse.isPublished,
      themeResponse.isPrimary,
      createdByModel
    );
  }

  public static hydrateFromImportedJson(themeJson: string): ThemeModel {
    const theme = JSON.parse(themeJson);

    if (theme.colors === undefined) {
      throw new Error('Theme is missing colors');
    }

    const themeColors = ThemeColorsModel.hydrateFromImportedJson(JSON.stringify(theme.colors));

    return new ThemeModel(
      theme.id ?? '',
      theme.name ?? '',
      undefined,
      undefined,
      theme.themeType ?? ThemeType.Light,
      theme.iconSet ?? ThemeIconSet.Normal,
      themeColors,
      false,
      undefined,
      undefined
    );
  }
}
