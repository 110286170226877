import EmailModel from '@Api/models/EmailModel';
import LanguageModel from '@Api/models/LanguageModel';
import ThemeModel from '@Api/models/ThemeModel';
import UserDiscordModel from '@Api/models/UserDiscordModel';

import { EmailResponse, Role, UserResponse } from '@Api-generated';

import { UserImageSrcModel } from '@Components/atoms/UserImage/UserImage';

// todo: replace with generated enum from backend as soon as native enums are supported in OpenApi
export enum AccountType {
  Free = 'free',
  Supporter = 'supporter',
}

export default class UserModel implements UserResponse {
  public id: string;
  public username: string;
  public gravatar: UserImageSrcModel;
  public shortId?: number;
  public legacyId?: number | null;
  public isActive?: boolean;
  public createdAt?: string;
  public updatedAt?: string;
  public usernameLastUpdatedAt?: string | null;
  public roles?: Role[];
  public accountType?: AccountType;
  public emails?: EmailModel[];
  public googleId?: string | null;
  public facebookId?: string | null;
  public userDiscord?: UserDiscordModel | null;
  public isCheater?: boolean;
  public hasNonCompliantProfile?: boolean;
  public hasNonCompliantUsername?: boolean;
  public isSpammer?: boolean;
  public isActivated?: boolean;
  public acceptsFriendRequests?: boolean;
  public theme?: ThemeModel;
  public language?: LanguageModel | null;
  public testsTaken?: number | null;

  constructor(
    id: string,
    username: string,
    gravatar: UserImageSrcModel,
    shortId?: number,
    emails?: EmailModel[],
    legacyId?: number | null,
    isActive?: boolean,
    createdAt?: string,
    updatedAt?: string,
    usernameLastUpdatedAt?: string | null,
    roles?: Role[],
    accountType?: AccountType,
    googleId?: string | null,
    facebookId?: string | null,
    userDiscord?: UserDiscordModel | null,
    isCheater?: boolean,
    hasNonCompliantProfile?: boolean,
    hasNonCompliantUsername?: boolean,
    isSpammer?: boolean,
    isActivated?: boolean,
    acceptsFriendRequests?: boolean,
    theme?: ThemeModel,
    language?: LanguageModel | null,
    testsTaken?: number | null
  ) {
    this.id = id;
    this.username = username;
    this.gravatar = gravatar;
    this.shortId = shortId;
    this.legacyId = legacyId;
    this.isActive = isActive;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.usernameLastUpdatedAt = usernameLastUpdatedAt;
    this.roles = roles;
    this.accountType = accountType;
    this.emails = emails;
    this.googleId = googleId;
    this.facebookId = facebookId;
    this.userDiscord = userDiscord;
    this.isCheater = isCheater;
    this.hasNonCompliantProfile = hasNonCompliantProfile;
    this.hasNonCompliantUsername = hasNonCompliantUsername;
    this.isSpammer = isSpammer;
    this.isActivated = isActivated;
    this.acceptsFriendRequests = acceptsFriendRequests;
    this.theme = theme;
    this.language = language;
    this.testsTaken = testsTaken;
  }

  public static hydrateFromApi(userResponse: UserResponse) {
    const emailModels = userResponse.emails
      ? userResponse.emails.map((email: EmailResponse) => EmailModel.hydrateFromApi(email))
      : [];

    const languageModel = userResponse.language ? LanguageModel.hydrateFromApi(userResponse.language) : null;

    const userDiscordModel = userResponse.userDiscord
      ? UserDiscordModel.hydrateFromApi(userResponse.userDiscord)
      : null;

    const accountType = userResponse.accountType as AccountType;

    const themeModel = userResponse.theme ? ThemeModel.hydrateFromApi(userResponse.theme) : undefined;

    return new UserModel(
      userResponse.id,
      userResponse.username,
      userResponse.gravatar,
      userResponse.shortId,
      emailModels,
      userResponse.legacyId,
      userResponse.isActive,
      userResponse.createdAt,
      userResponse.updatedAt,
      userResponse.usernameLastUpdatedAt,
      userResponse.roles,
      accountType,
      userResponse.googleId,
      userResponse.facebookId,
      userDiscordModel,
      userResponse.isCheater,
      userResponse.hasNonCompliantProfile,
      userResponse.hasNonCompliantUsername,
      userResponse.isSpammer,
      userResponse.isActivated,
      userResponse.acceptsFriendRequests,
      themeModel,
      languageModel,
      userResponse.testsTaken
    );
  }
}
