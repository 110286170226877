export const removeSeparator = (words: string) => {
  return words.replace(/\|/g, ' ');
};

export const addSeparator = (words: string) => {
  return words.replace(/(\s)/g, '|');
};

export const sanitizeWords = (words: string) => {
  // remove multiple spaces
  const sanitizedWords = words.replace(/\s\s+/g, ' ');

  return sanitizedWords.trim();
};
